import React, { useState,useEffect } from 'react';
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { A } from '../components/styles'
import Layout from "../components/layout"


export const pageQuery = graphql`
 query {
    allStrapiJobs{
        edges {
         node {
           seo {
            metaTitle
            metaDescription
            shareImage {
                url
            }
           }
           title {
               title
               description
           }
           slogan
           image_group {
            image {
                localFile {
                    childImageSharp {
                      gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
           }
           positions {
            city
            positions {
                title
                info
                url
            }
           }
           hood {
               title
               description
           }
         }
       }
    }
 }`
 
export default function IndexPage({location,data}) {
    const node = data.allStrapiJobs.edges[0].node
    const [positionsNum,setPositionsNum] = useState(0)

    useEffect(()=>{
        let k = 0
        for(let i=0;i<node.positions.length;i++){
            for(let j=0;j<node.positions[i].positions.length;j++){
                k++
            }
        }
        setPositionsNum(k)
    },[])

    return (
        <Layout>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'gemfisher',
              }}
            />
            <div className="pb-[200px] lg:pb-[120px]">
                <h1 data-aos="fade-up" data-aos-duration="600" className="text-[200px] leading-none pt-[260px] pb-[95px] lg:pt-[165px] xl:text-[150px] lg:text-[72px]">{node.title.title}</h1>
                <p data-aos="fade-up" data-aos-duration="600" className="text-[72px] leading-tight pb-[200px] com-indent lg:pb-[120px] xl:text-[56px] lg:text-[32px]">{node.title.description}</p>
                <div className="grid gap-4 grid-cols-4 pb-[200px] md:grid-cols-1 lg:pb-[120px]">
                    <div></div>
                    {
                        node.image_group.map((n,i)=>{
                            if(i<4){
                            return (
                                <div data-aos="fade-up" data-aos-duration={600+i*200} key={i} className={!i?"col-span-3":""}>
                                    <figure className="h-0 w-full pt-[66.67%] relative">
                                        <GatsbyImage className="w-full h-full object-cover bg-light absolute left-0 top-0" image={getImage(n.image.localFile)} alt="banner"/>
                                    </figure>
                                </div>
                            )}
                        })
                    }
                    <div></div>
                    <div className="col-span-2"></div>
                    <div className="col-span-2">
                        <figure data-aos="fade-up" data-aos-duration={1400}>
                            <GatsbyImage className="w-full bg-light" image={getImage(node.image_group[4].image.localFile)} alt="banner"/>
                        </figure>
                    </div>
                </div>
                <p data-aos="fade-up" data-aos-offset="300" data-aos-duration="600" className="text-[72px] leading-tight pb-[200px] lg:pb-[120px] com-indent xl:text-[56px] lg:text-[32px]">{node.slogan}</p>
                <div className="grid grid-cols-12 pb-[200px] lg:pb-[120px] xl:grid-cols-1">
                    <h2 className="text-[56px] col-span-3 sticky top-16 pr-8 xl:pr-0 xl:mb-10 xl:static xl:text-[40px]">Take a look under the hood</h2>
                    <div className="col-span-6 border-t border-black">
                        {
                            node.hood.map((n,i)=>{
                                return (
                                    <details key={i}>
                                        <summary>{n.title}</summary>
                                        <div>
                                            {n.description}
                                        </div>
                                    </details>
                                )
                            })
                        }
                    </div>
                </div>
                <h2 data-aos="fade-up" data-aos-duration="600" className="text-[150px] leading-none pb-[95px] flex justify-between items-center xl:text-[120px] lg:text-[calc(40px+10vw)] lg:flex-col lg:items-start">Open positions <span>{positionsNum}</span></h2>
                {
                    node.positions.map((n,i)=>{
                        return (
                            <div className="flex pb-[116px] border-t border-black pt-14 xs:flex-col xs:pb-14" key={i}>
                                <h3 className="text-[40px] leading-tight w-1/2 xs:w-full xs:mb-10">{n.city}</h3>
                                <div className="w-1/2 xs:w-full">
                                    {
                                        n.positions.map((c,k)=>{
                                            return (
                                                <div data-aos="fade-up" data-aos-duration={600+i*400} key={k} className="text-lg pb-8">
                                                    <h4 className="font-bold pb-2"><A href={c.url}>{c.title}</A></h4>
                                                    <p>{c.info}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </Layout>
    )
}

